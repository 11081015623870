import { Link } from "react-router-dom";
import { FaFacebookF, FaTiktok } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLevelUpAlt } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncSocialLinks } from "../Redux/features/CommonSlice";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  const socialLink = useSelector((state) => state.Common.socialLinks);
  console.log(socialLink);

  return (
    <>
      <footer className="footer-bg">
        <div className="footer-area section-bg pt-4">
          <div className="container">
            <div className="footer-top footer-padding">
              <div className="row d-flex justify-content-between">
                <div className="col-xl-3 col-lg-4 col-md-5 col-sm-8">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-logo">
                      <img
                        src="images/salonLogo.webp"
                        style={{ height: "90px" }}
                        alt=""
                      />
                    </div>
                    <div className="footer-tittle">
                      <div className="footer-pera">
                        <p className="info1">
                          OUR HAIR STYLE MAKE YOUR LOOK ELEGANCE
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-5">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                      <h4>Pages</h4>
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="about"> About</Link>
                        </li>
                        <li>
                          <Link to="service">Services</Link>
                        </li>
                        <li>
                          <Link to="gallery">Gallery</Link>
                        </li>
                        <li>
                          <Link to="contact">Contact</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                      <h4>Location</h4>
                      <div className="footer-pera">
                        <p className="secondary-text-color">
                          <FaMapMarkerAlt className="me-1" /> Lebanon
                        </p>

                        <Link style={{ color: "var(--text-secondary-color)" }}>
                          <FaLocationArrow className="me-1" /> Get Direction
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row d-flex justify-content-between align-items-center pt-3">
                <div className="col-xl-9 col-lg-8 col-6">
                  <div className="footer-copy-right">
                    <p className="secondary-text-color">
                      Copyright &copy;
                      <script>document.write(new Date().getFullYear());</script>
                      2023 -{" "}
                      <a
                        href="https://abss.tech"
                        target="blank"
                        style={{ color: "var(--text-primary-color)" }}
                      >
                        ABSS
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-6 text-end">
                  <div className=" pt-0">
                    <a href={socialLink?.facebook} rel="noreferrer" target="_blank">
                      <FaFacebookF className="fs-3 secondary-text-color" />
                    </a>
                    <a href={socialLink?.instagram} rel="noreferrer" target="_blank">
                      <FaInstagram className="fs-3 secondary-text-color mx-3" />
                    </a>
                    <a href={socialLink?.tiktok} rel="noreferrer" target="_blank">
                      <FaTiktok className="fs-3 secondary-text-color" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div
        id="back-top"
        className={`back-to-top-button ${isVisible ? "visible" : ""}`}
        onClick={scrollToTop}
      >
        <FaLevelUpAlt style={{ color: "var(--secondary-color)" }} />
      </div>
    </>
  );
};

export default Footer;
