import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncGetHome } from "../Redux/features/HomeSlice";

const HomeBanner = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncGetHome());
  }, [dispatch]);
  const homeData = useSelector((state) => state.Home.home);

  return (
    <>
      <div class="slider-area mt-lg-0 mt-5 pt-lg-0">
        <div class="img-gradient ">
          <div
            class="single-slider slider-height d-flex align-items-center justify-content-center"
            style={{
              backgroundImage: "url(/images/homeBanner.webp)",
            }}
          >
            <div class="container">
              <div class="row">
                <div class="col-xl-9 col-md-12">
                  <div class="slider-content slider-content-2">
                    <h1
                      class="secondary-text-color f-700"
                      data-animation="fadeInUp"
                      data-delay=".2s"
                    >
                      <br />
                      {homeData?.title}
                    </h1>
                    <p
                      className="secondary-text-color"
                      data-animation="fadeInUp"
                      data-delay=".4s"
                    >
                      {homeData?.subTitle}
                    </p>
                    <button
                      className="theme-btn btn--tra-black hover--black"
                      data-animation="fadeInUp"
                      data-delay=".6s"
                    >
                      <a href="#waitlist" class="btn-text">
                        Book an Appointment
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBanner;
