import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncSchedule } from "../Redux/features/CommonSlice";
import { getAsyncContact } from "../Redux/features/ContactSlice";

const OurLocation = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncSchedule());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAsyncContact());
  }, [dispatch]);

  const getSchedule = useSelector((state) => state.Common.schedule);
  const getContact = useSelector((state) => state.Contact.contacts);

console.log("getSchedule", getSchedule)

  return (
    <section className="py-default  content-section division">
      <div className="container text-center">
        <div className="mb-5 ct-table content-section division">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6">
                <div className="left-column wow fadeInRight">
                  <span className="section-id">Location</span>

                  <div className="txt-table right-column wow fadeInLeft">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td><p className="mb-0">Address</p></td>
                          <td> <p className="mb-0">-</p> </td>
                          <td class="text-end"><p className="mb-0">{getContact?.address}</p></td>
                        </tr>
                        <tr>
                          <td><p className="mb-0">Phone</p></td>
                          <td> <p className="mb-0">-</p> </td>
                          <td class="text-end"><p className="mb-0">{getContact?.phone}</p></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mt-lg-0 mt-3">
                <span className="section-id">Working Hours</span>
                <div className="txt-table right-column wow fadeInLeft">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td><p className="mb-0">Mon – Friday</p></td>
                        <td> <p className="mb-0">-</p> </td>
                        <td class="text-end"><p className="mb-0">{getSchedule?.openTime} AM</p></td>
                      </tr>
                      <tr>
                        <td><p className="mb-0">Sat - Sun</p></td>
                        <td> <p className="mb-0">-</p> </td>
                        <td class="text-end"><p className="mb-0">{getSchedule?.closeTime} PM</p></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurLocation;
