import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const fetchAsyncGetHome = createAsyncThunk("home/getHome", async () => {
  try {
    const res = await axiosInstance.get("/barbershop/home");
    console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
});

const initialState = {
  home: [],
  loading: "idle",
  error: null,
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncGetHome.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchAsyncGetHome.fulfilled, (state, action) => {
        state.home = action.payload;
        state.loading = "succeeded";
      })
      .addCase(fetchAsyncGetHome.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = "failed";
      });
  },
});

export default homeSlice.reducer;
