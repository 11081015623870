import React from "react";
import { Link } from "react-router-dom";
import { getAsyncFavoriteImages } from "../Redux/features/GallerySlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const images = [
  { img: "images/gallery/gallery1.webp" },
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery3.webp" },
  { img: "images/gallery/gallery1.webp" },
];
const GallerySection = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAsyncFavoriteImages());
  }, [dispatch]);
  const favoriteImages = useSelector((state) => state.Gallery.favoriteImage);

  return (
    <div id="gallery-4" className="section-bg-color py-default px-5">
      <div>
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <div className="section-title text-center mb-3">
              <h2 className="h2-title">Gallery</h2>
            </div>
          </div>
        </div>
        <div className="">
          <div className="row g-3 justify-content-center">
            {favoriteImages?.slice(0, 4).map((image, index) => (
              <div key={index} className="col-md-6 col-lg-3 col-12 ">
                <div className="de-image-hover rounded">
                  <span className="dih-overlay"></span>

                  <img
                    src={image.url}
                    className="gallery-img"
                    alt="..."
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="row mt-5">
          <div className="col">
            <div className="more-btn">
              <a href="#gallery-head">
                <Link to="/gallery" className="btn btn--tra-black hover--black">
                  Visit Our Gallery
                </Link>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection;
