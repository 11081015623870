import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncEmployees } from "../Redux/features/AboutSlice";
import Loading from "./Loading";

const OurTeam = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncEmployees());
  }, [dispatch]);

  const employees = useSelector((state) => state.About.employees);
  const status = useSelector((state) => state.About.employeesStatus);

  return (
    <section id="blog-1" class="pt-5 mb-5 blog-section division">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-8">
            <div class="section-title text-center mb-3">
              <h2 class="h2-title">Meet Our Team</h2>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          {status === "loading" ? (
            <Loading />
          ) : (
            <>
              {employees?.map((team, index) => (
                <div key={index} class="col-md-6 col-lg-3 pb-4">
                  <div id="bp-1-1" class="blog-post wow fadeInUp">
                    <div class="blog-post-img">
                      <div class="de-image-hover rounded-3">
                        <span className="hover-overlay"></span>
                        <img
                          class="team-img rounded-3"
                          src={team.url}
                          alt="Team member"
                        />
                        <div class="item-overlay"></div>
                      </div>
                    </div>
                    <div class="blog-post-txt">
                      <h5 class="h5-md post-link text-center pt-4">
                        {team.name}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </section>
  );
};
export default OurTeam;
