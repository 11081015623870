import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";

const Preloader = () => {
  const [isVisible, setIsVisible] = useState(true);

  const fade = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    delay: 2000,
    config: { duration: 1000 },
    onRest: () => setIsVisible(false),
  });

  return (
    <>
      {isVisible && (
        <animated.div style={fade} className="mil-preloader">
          <div className="preloader d-flex align-items-center justify-content-center">
            <div className="preloader-inner position-relative">
              <div
                className="spinner-grow"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
              <div
                className="spinner-grow mx-3"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
              <div
                className="spinner-grow"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </animated.div>
      )}
    </>
  );
};

export default Preloader;
