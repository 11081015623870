import { configureStore } from "@reduxjs/toolkit";
import ContactSlice from "./features/ContactSlice";
import HomeSlice from "./features/HomeSlice";
import scheduleSlice from "./features/ScheduleSlice";
import AboutSlice from "./features/AboutSlice";
import commonSlice from "./features/CommonSlice";
import GallerySlice from "./features/GallerySlice";

const store = configureStore({
  reducer: {
    Home: HomeSlice,
    Common: commonSlice,
    About: AboutSlice,
    Contact: ContactSlice,
    Gallery: GallerySlice,
    Schedule: scheduleSlice,

  },
});

export default store;
