import * as Yup from "yup";

export const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  phone: Yup.string()
    .matches(
      // /^\+?(?:961)?(?:1|0?3[0-9]?|[4-6]|70|71|76|78|79|7|81?|9)\d{7}$/,
      // /^(70|71|76|78|79|81|03|3)\d{6}$/,
      /^(?:(?:\+|00)961)?(?:0|1)?[0-9]\d{7}$/,
      "Phone Number Must be Lebanese Number"
    )
    .required("Required"),
  subject: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  message: Yup.string()
    .min(2, "Too Short!")
    .max(500, "Too Long!")
    .required("Required"),
});
