import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "https://ro96qz3xk7.execute-api.eu-central-1.amazonaws.com/test",
  headers: {
    barberId: process.env.REACT_APP_BARBER_ID || "03ab1d12-53fa-49b1-8a48-6a1cba17d96f",
  },
});

export default axiosInstance;
