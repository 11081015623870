import React from "react";
import ReactDOM from "react-dom";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routes/Routers";
import "../src/assets/CSS/menu.css";
import "../src/assets/CSS/flaticon.css";
import "../src/assets/CSS/bootstrap.min.css";
import "../src/assets/CSS/style.css";
import "../src/assets/CSS/custom.css";
import "../src/assets/CSS/responsive.css";
import store from "./Redux/store";
import { Provider } from "react-redux";

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router}></RouterProvider>
    </Provider>
  // </React.StrictMode>
);
