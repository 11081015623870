import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";


export const fetchAsyncGetSchedule = createAsyncThunk(
  "schedule/getSchedule",
  async () => {
    try {
      const res = await axiosInstance.get("/barbershop/schedule");
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

const initialState = {
  schedule: [],
  loading: "idle",
  error: null,
};

const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncGetSchedule.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchAsyncGetSchedule.fulfilled, (state, action) => {
        state.schedule = action.payload;
        state.loading = "succeeded";
      })
      .addCase(fetchAsyncGetSchedule.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = "failed";
      });
  },
});

export default scheduleSlice.reducer;