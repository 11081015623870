import { useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";

import WaitList from "../WaitList";
import SelectService from "../SelectService";
import SelectEmployee from "../SelectEmployee";
import DateAndTime from "../DateAndTime";
import UserInfo from "../UserInfo";
import Otp from "../Otp";

const Wizard = () => {
  const [goSteps, setGoSteps] = useState(0);
  useEffect(() => {
    const element = document.getElementById('waitlist');
    if (element) element.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <>
      <div id="waitlist" className="container py-default">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className=""></div>
            <div className="card-body">
              <div>
                <Stepper
                  className="container"
                  activeStep={goSteps}
                  activeTextColor="#000"
                >
                  <Step
                    style={{
                      backgroundColor:
                        goSteps >= 0
                          ? "var(--primary-color)"
                          : "var(--secondary-color)",
                    }}
                    onClick={() => setGoSteps(0)}
                  />
                  <Step
                    style={{
                      backgroundColor:
                        goSteps >= 1
                          ? "var(--primary-color)"
                          : "var(--secondary-color)",
                    }}
                    onClick={() => setGoSteps(1)}
                  />
                  <Step
                    style={{
                      backgroundColor:
                        goSteps >= 2
                          ? "var(--primary-color)"
                          : "var(--secondary-color)",
                    }}
                    onClick={() => setGoSteps(2)}
                  />
                  <Step
                    style={{
                      backgroundColor:
                        goSteps >= 3
                          ? "var(--primary-color)"
                          : "var(--secondary-color)",
                    }}
                    onClick={() => setGoSteps(3)}
                  />
                  <Step
                    style={{
                      backgroundColor:
                        goSteps >= 4
                          ? "var(--primary-color)"
                          : "var(--secondary-color)",
                    }}
                    onClick={() => setGoSteps(4)}
                  />
                  <Step
                    style={{
                      backgroundColor:
                        goSteps >= 5
                          ? "var(--primary-color)"
                          : "var(--secondary-color)",
                    }}
                    onClick={() => setGoSteps(5)}
                  />
                </Stepper>
                {goSteps === 0 && (
                  <>
                    <WaitList setGoSteps={setGoSteps} />
                    <div className="text-end toolbar toolbar-bottom p-2"></div>
                  </>
                )}
                {goSteps === 1 && (
                  <>
                    <SelectService setGoSteps={setGoSteps} />
                  </>
                )}
                {goSteps === 2 && (
                  <>
                    <SelectEmployee setGoSteps={setGoSteps} />
                  </>
                )}
                {goSteps === 3 && (
                  <>
                    <DateAndTime setGoSteps={setGoSteps} />
                  </>
                )}
                {goSteps === 4 && (
                  <>
                    <UserInfo setGoSteps={setGoSteps} />
                  </>
                )}
                {goSteps === 5 && (
                  <>
                    <Otp setGoSteps={setGoSteps} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wizard;
