import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncAboutDetails } from "../Redux/features/AboutSlice";
import Loading from "./Loading";

const AboutText = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAsyncAboutDetails());
  }, [dispatch]);

  const aboutDetails = useSelector((state) => state.About.about);
  const status = useSelector((state) => state.About.status);

  return (
    <section className="py-default ct-01 content-section division">
      <div className="container">
        <div className="row  d-flex align-items-center">
          <div className="col-lg-6 order-last order-lg-2">
            <div className="txt-block left-column wow fadeInRight">
              {status === "loading" ? (
                <Loading />
              ) : (
                <>
                  <h2 className="h2-md">{aboutDetails?.title}</h2>
                  <p className="mb-0">{aboutDetails?.description}</p>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6 order-first order-lg-2">
            <div className="img-block left-column wow fadeInRight ">
              <img
                className="img-fluid w-100 rounded-4"
                src="images/team/team1.webp"
                alt="content-image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutText;
