import React, { useEffect } from "react";
import HomeBanner from "../Component/HomeBanner";
import Services from "../Component/Services";
import GallerySection from "../Component/GallerySection";
import OurLocation from "../Component/OurLocation";
import TrendingSection from "../Component/TrendingSection";
import Wizard from "../Component/WaitList/Steps/Wizard";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HomeBanner />
      <Wizard></Wizard>
      <Services isInServices={false} />
      <TrendingSection />
      <GallerySection />
      <OurLocation />
    </>
  );
};

export default Home;
