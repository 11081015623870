import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAsyncCategories } from "../Redux/features/CommonSlice";

const Services = (props) => {
  const { isInServices } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncCategories());
  }, [dispatch]);
  const categories = useSelector((state) => state.Common.categories);
  return (
    <section
      id="services-2"
      className="section-bg-color py-default services-section division"
    >
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-lg-10 col-xl-9">
            <div className="txt-block text-center">
              <h2 className="h2-title mb-5">Your Secret Place of Beauty</h2>
            </div>
          </div>
        </div>
        <div className="sbox-2-wrapper text-center">
          <div className="row justify-content-center">
            {categories?.slice(0, 4).map((category) => (
              <div className="col-12 col-md-4 col-lg-3">
                <div className="sbox-2 sb-1 wow fadeInUp">
                  <div className="sbox-ico ico-65">
                    <img className="service-img rounded-1 " src={category.url} alt="category" />
                  </div>
                  <div className="sbox-txt">
                    <h5 className="h5-lg">{category.name}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {!isInServices && (
          <div class="row mt-5">
            <div class="col">
              <div class="more-btn">
                <a href="#service-head">
                  <Link
                    to="/service"
                    className="btn btn--tra-black hover--black"
                  >
                    See All Services
                  </Link>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Services;
