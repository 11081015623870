import React, { useEffect } from "react";
import NavBar from "../partials/NavBar";
import { Outlet } from "react-router-dom";
import Footer from "../partials/Footer";
import { getAsyncSocialLinks } from "../Redux/features/CommonSlice";
import { useDispatch } from "react-redux";

const Main = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getAsyncSocialLinks());
  }, [dispatch]);

  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
};

export default Main;
