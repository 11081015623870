import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncCategories } from "../Redux/features/CommonSlice";

const ServicePrice = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncCategories());
  }, [dispatch]);
  const categories = useSelector((state) => state.Common.categories);

  return (
    <>
      <div
        className="py-8 pricing-5 pricing-section division "
        id="service-head"
      >
        <div className="container">
          <div className="pricing-5-wrapper">
            <div className="row">
              {categories
                .filter(
                  (category) =>
                    category.services && category.services.length > 0
                )
                .map((category) => (
                  <div key={category.id} className="col-lg-6 py-2">
                    <div className="pricing-5-table left-column wow fadeInUp">
                      <div className="pricing-5-category mb-4">
                        <h3 style={{ color: "var(--text-tertiary-color" }}>
                          {category.name}
                        </h3>
                      </div>
                      {category.services.map((service) => (
                        <ul className="pricing-list py-2">
                          <li className="pricing-5-item">
                            <div className="detail-price">
                              <div className="price-name">
                                <p>{service.name}</p>
                              </div>
                              <div className="price-dots"></div>
                              <div className="price-number">
                                <p>${service.waitlistPriceInDollar}</p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePrice;
